import React, { useEffect } from 'react'
import appStoreImage from '../../img/appStore3x.png'
import playStoreImage from '../../img/playStore3x.png'
import scanner from '../../img/WAQR3x.png'

function PreRegFooter() {
    useEffect(() => {
        function scrollToTop() {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    
        const anchors = document.querySelectorAll('.scroll-to-top');
        anchors.forEach(anchor => anchor.addEventListener('click', scrollToTop));
    
        return () => {
          anchors.forEach(anchor => anchor.removeEventListener('click', scrollToTop));
        };
      }, []);
    
  return (
    <div class="section">
    <div class="section7">
        <div class="section7Inner">
            <div class="footerUpper">
                <div>
                    <svg onclick="window.location.href = './index.html'" class="main-logo" width="114"
                        height="44" viewBox="0 0 85 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M39.9681 5.00335C39.9681 5.73383 39.8752 6.3983 39.6895 6.99676C39.5038 7.58643 39.2252 8.09688 38.8538 8.52813C38.4913 8.95938 38.0358 9.28941 37.4876 9.51824C36.9481 9.74706 36.3203 9.86148 35.604 9.86148C34.8611 9.86148 34.2156 9.74706 33.6673 9.51824C33.119 9.28061 32.6636 8.95058 32.301 8.52813C31.9384 8.09688 31.6687 7.58203 31.4919 6.98356C31.315 6.3851 31.2266 5.72062 31.2266 4.99014C31.2266 4.02204 31.3857 3.17715 31.7041 2.45547C32.0225 1.73379 32.5044 1.17053 33.15 0.765684C33.8044 0.36084 34.6268 0.158418 35.6172 0.158418C36.5634 0.158418 37.3593 0.36084 38.0049 0.765684C38.6504 1.16173 39.1368 1.72499 39.464 2.45547C39.8001 3.17715 39.9681 4.02644 39.9681 5.00335ZM32.4867 5.00335C32.4867 5.79543 32.5973 6.47751 32.8183 7.04957C33.0394 7.62163 33.3799 8.06168 33.8397 8.36971C34.3084 8.67775 34.8965 8.83177 35.604 8.83177C36.3203 8.83177 36.9039 8.67775 37.3549 8.36971C37.8148 8.06168 38.1552 7.62163 38.3763 7.04957C38.5974 6.47751 38.7079 5.79543 38.7079 5.00335C38.7079 3.81522 38.4603 2.88672 37.9651 2.21784C37.4699 1.54017 36.6872 1.20133 35.6172 1.20133C34.9009 1.20133 34.3084 1.35535 33.8397 1.66338C33.3799 1.96261 33.0394 2.39826 32.8183 2.97032C32.5973 3.53359 32.4867 4.21126 32.4867 5.00335Z"
                            fill="white" />
                        <path
                            d="M47.4351 2.52148C47.5677 2.52148 47.7092 2.53028 47.8595 2.54788C48.0187 2.55668 48.1558 2.57428 48.2707 2.60068L48.1248 3.67C48.0099 3.6436 47.8816 3.6216 47.7402 3.60399C47.6075 3.58639 47.4793 3.57759 47.3555 3.57759C47.0813 3.57759 46.8205 3.6348 46.5728 3.74921C46.3252 3.86362 46.1042 4.02644 45.9096 4.23766C45.7151 4.44008 45.5603 4.68651 45.4453 4.97694C45.3392 5.26737 45.2862 5.59301 45.2862 5.95385V9.72946H44.1189V2.65349H45.0739L45.2066 3.94723H45.2596C45.41 3.6832 45.5913 3.44558 45.8035 3.23435C46.0157 3.01433 46.2589 2.84271 46.5331 2.7195C46.8072 2.58748 47.1079 2.52148 47.4351 2.52148Z"
                            fill="white" />
                        <path
                            d="M52.9765 2.65349V9.72946H51.8092V2.65349H52.9765ZM52.4061 0C52.5829 0 52.7377 0.0616067 52.8704 0.18482C53.0118 0.299233 53.0826 0.484053 53.0826 0.739281C53.0826 0.985707 53.0118 1.17053 52.8704 1.29374C52.7377 1.41695 52.5829 1.47856 52.4061 1.47856C52.2115 1.47856 52.0479 1.41695 51.9153 1.29374C51.7826 1.17053 51.7163 0.985707 51.7163 0.739281C51.7163 0.484053 51.7826 0.299233 51.9153 0.18482C52.0479 0.0616067 52.2115 0 52.4061 0Z"
                            fill="white" />
                        <path
                            d="M59.9601 2.52148C60.4288 2.52148 60.8489 2.60949 61.2203 2.7855C61.6005 2.96152 61.9233 3.22995 62.1886 3.59079H62.2549L62.4141 2.65349H63.3427V9.84828C63.3427 10.5171 63.2277 11.076 62.9978 11.5249C62.7678 11.9825 62.4185 12.3257 61.9498 12.5546C61.4812 12.7834 60.8842 12.8978 60.1591 12.8978C59.6462 12.8978 59.1731 12.8582 58.7398 12.779C58.3153 12.7086 57.935 12.5986 57.599 12.449V11.3796C57.8289 11.4941 58.0809 11.5909 58.3551 11.6701C58.6292 11.7581 58.9255 11.8241 59.2438 11.8681C59.5622 11.9121 59.8894 11.9341 60.2254 11.9341C60.8356 11.9341 61.3131 11.7537 61.658 11.3928C62.0117 11.0408 62.1886 10.5568 62.1886 9.94069V9.66346C62.1886 9.55784 62.193 9.40823 62.2019 9.21461C62.2107 9.01218 62.2196 8.87137 62.2284 8.79216H62.1753C61.9277 9.153 61.6182 9.42143 61.2468 9.59745C60.8842 9.77347 60.4598 9.86148 59.9734 9.86148C59.0537 9.86148 58.333 9.54024 57.8112 8.89777C57.2983 8.2553 57.0419 7.3576 57.0419 6.20468C57.0419 5.44779 57.1568 4.79652 57.3867 4.25086C57.6255 3.6964 57.9616 3.26956 58.3949 2.97032C58.8282 2.67109 59.3499 2.52148 59.9601 2.52148ZM60.1193 3.49838C59.7214 3.49838 59.3809 3.60399 59.0979 3.81522C58.8238 4.02644 58.6115 4.33447 58.4612 4.73932C58.3197 5.14416 58.249 5.63702 58.249 6.21788C58.249 7.08917 58.4081 7.75805 58.7265 8.2245C59.0537 8.68215 59.5268 8.91097 60.1458 8.91097C60.5084 8.91097 60.8179 8.86697 61.0744 8.77896C61.3308 8.68215 61.5431 8.53693 61.7111 8.34331C61.8791 8.14089 62.0029 7.88566 62.0825 7.57763C62.1621 7.26959 62.2019 6.90435 62.2019 6.48191V6.20468C62.2019 5.56221 62.1267 5.04295 61.9764 4.64691C61.8349 4.25086 61.6094 3.96043 61.2999 3.77561C60.9904 3.59079 60.5968 3.49838 60.1193 3.49838Z"
                            fill="white" />
                        <path
                            d="M73.993 6.17827C73.993 6.76794 73.9134 7.2916 73.7542 7.74925C73.6039 8.1981 73.3828 8.58094 73.091 8.89777C72.808 9.21461 72.4587 9.45663 72.0431 9.62385C71.6363 9.78227 71.1808 9.86148 70.6768 9.86148C70.2081 9.86148 69.7748 9.78227 69.3768 9.62385C68.9789 9.45663 68.634 9.21461 68.3422 8.89777C68.0503 8.58094 67.8204 8.1981 67.6524 7.74925C67.4932 7.2916 67.4136 6.76794 67.4136 6.17827C67.4136 5.39499 67.5463 4.73492 67.8116 4.19806C68.0769 3.6524 68.4571 3.23875 68.9524 2.95712C69.4476 2.66669 70.0356 2.52148 70.7166 2.52148C71.3621 2.52148 71.9281 2.66669 72.4145 2.95712C72.9097 3.23875 73.2944 3.6524 73.5685 4.19806C73.8515 4.73492 73.993 5.39499 73.993 6.17827ZM68.6207 6.17827C68.6207 6.73274 68.6915 7.21679 68.833 7.63043C68.9833 8.03528 69.2132 8.34771 69.5227 8.56774C69.8323 8.78776 70.2258 8.89777 70.7033 8.89777C71.1808 8.89777 71.5744 8.78776 71.8839 8.56774C72.1934 8.34771 72.4189 8.03528 72.5604 7.63043C72.7107 7.21679 72.7859 6.73274 72.7859 6.17827C72.7859 5.61501 72.7107 5.13536 72.5604 4.73932C72.4101 4.34327 72.1801 4.03964 71.8706 3.82842C71.5699 3.60839 71.1764 3.49838 70.69 3.49838C69.9649 3.49838 69.4387 3.73601 69.1115 4.21126C68.7843 4.68651 68.6207 5.34218 68.6207 6.17827Z"
                            fill="white" />
                        <path
                            d="M16.5181 30.4931V13.3503H21.7694L24.9226 25.0439L28.0402 13.3503H33.3035V30.4931H30.0436V16.9987L26.594 30.4931H23.2156L19.7779 16.9987V30.4931H16.5181Z"
                            fill="white" />
                        <path
                            d="M39.4749 21.8632L36.4521 21.3253C36.7919 20.1248 37.3767 19.2361 38.2065 18.6592C39.0363 18.0823 40.2691 17.7939 41.905 17.7939C43.3907 17.7939 44.4971 17.9693 45.2242 18.3201C45.9512 18.6631 46.4609 19.1036 46.7533 19.6415C47.0536 20.1716 47.2038 21.1499 47.2038 22.5765L47.1682 26.412C47.1682 27.5034 47.2196 28.3103 47.3223 28.8326C47.433 29.3471 47.6345 29.9006 47.9269 30.4931H44.6315C44.5445 30.2748 44.4378 29.9513 44.3114 29.5225C44.2561 29.3276 44.2166 29.199 44.1929 29.1366C43.6239 29.6823 43.0153 30.0916 42.3673 30.3644C41.7193 30.6373 41.0278 30.7737 40.2929 30.7737C38.9968 30.7737 37.9734 30.4268 37.2226 29.733C36.4798 29.0392 36.1084 28.1621 36.1084 27.1019C36.1084 26.4003 36.2783 25.7767 36.6181 25.231C36.9579 24.6775 37.4321 24.2565 38.0406 23.9681C38.657 23.6718 39.5421 23.4146 40.6959 23.1963C42.2527 22.9079 43.3315 22.6389 43.9321 22.3894V22.062C43.9321 21.4306 43.774 20.9823 43.4579 20.7173C43.1418 20.4444 42.5451 20.308 41.6679 20.308C41.0752 20.308 40.6129 20.4249 40.281 20.6588C39.9491 20.8849 39.6804 21.2863 39.4749 21.8632ZM43.9321 24.5294C43.5053 24.6697 42.8296 24.8373 41.905 25.0322C40.9804 25.2271 40.3758 25.4181 40.0913 25.6052C39.6567 25.9092 39.4394 26.2951 39.4394 26.7628C39.4394 27.2228 39.6132 27.6203 39.9609 27.9556C40.3087 28.2908 40.7512 28.4584 41.2886 28.4584C41.8892 28.4584 42.4622 28.2635 43.0074 27.8737C43.4105 27.5775 43.6752 27.215 43.8017 26.7862C43.8886 26.5056 43.9321 25.9716 43.9321 25.1842V24.5294Z"
                            fill="white" />
                        <path
                            d="M62.171 30.4931H58.84V24.1552C58.84 22.8143 58.7689 21.949 58.6266 21.5592C58.4844 21.1616 58.2512 20.8537 57.9272 20.6354C57.6111 20.4171 57.2278 20.308 56.7774 20.308C56.2005 20.308 55.6828 20.4639 55.2245 20.7757C54.7661 21.0876 54.45 21.5007 54.2762 22.0152C54.1102 22.5298 54.0272 23.4808 54.0272 24.8685V30.4931H50.6962V18.0745H53.7901V19.8987C54.8886 18.4955 56.2716 17.7939 57.9391 17.7939C58.674 17.7939 59.3458 17.9264 59.9543 18.1915C60.5628 18.4487 61.0211 18.78 61.3293 19.1854C61.6455 19.5908 61.8628 20.0507 61.9813 20.5652C62.1078 21.0798 62.171 21.8165 62.171 22.7753V30.4931Z"
                            fill="white" />
                        <path
                            d="M77.3278 30.4931H74.2339V28.6689C73.7203 29.3783 73.1117 29.9084 72.4084 30.2592C71.713 30.6022 71.0096 30.7737 70.2984 30.7737C68.8522 30.7737 67.6114 30.2007 66.5762 29.0548C65.5488 27.901 65.0352 26.2951 65.0352 24.237C65.0352 22.1322 65.537 20.5341 66.5406 19.4427C67.5443 18.3435 68.8127 17.7939 70.3458 17.7939C71.7525 17.7939 72.9695 18.3708 73.9968 19.5245V13.3503H77.3278V30.4931ZM68.4373 24.0148C68.4373 25.3401 68.623 26.299 68.9944 26.8914C69.5318 27.749 70.2826 28.1777 71.2467 28.1777C72.0133 28.1777 72.6652 27.8581 73.2026 27.2189C73.74 26.5718 74.0087 25.6091 74.0087 24.3306C74.0087 22.904 73.7479 21.8788 73.2263 21.2552C72.7048 20.6237 72.037 20.308 71.223 20.308C70.4327 20.308 69.7689 20.6198 69.2315 21.2435C68.702 21.8593 68.4373 22.7831 68.4373 24.0148Z"
                            fill="white" />
                        <path
                            d="M80.844 16.3907V13.3503H84.175V16.3907H80.844ZM80.844 30.4931V18.0745H84.175V30.4931H80.844Z"
                            fill="white" />
                        <path
                            d="M11.5401 21.8275C11.5401 22.1802 11.4327 22.5887 11.2178 23.0531C11.0029 23.5089 10.6075 23.9949 10.0315 24.5109C9.46421 25.0269 8.64759 25.4827 7.58168 25.8783C6.52437 26.274 5.19199 26.5449 3.58454 26.6911L3.27508 26.7169C3.20631 27.0609 3.17193 27.3791 3.17193 27.6715C3.17193 28.308 3.32236 28.7681 3.62322 29.0519C3.93268 29.3271 4.37967 29.4647 4.9642 29.4647C6.2536 29.4647 7.48713 28.7681 8.66478 27.3748H10.0315C9.49859 28.1919 8.69057 29.0003 7.60747 29.8001C6.52437 30.6 5.23927 30.9999 3.75216 30.9999C2.72064 30.9999 1.83525 30.7247 1.09599 30.1743C0.36533 29.6152 0 28.7982 0 27.7231C0 26.4073 0.36533 25.1301 1.09599 23.8916C1.82665 22.6446 2.82379 21.634 4.0874 20.86C5.35102 20.0774 6.72638 19.686 8.21349 19.686C9.40833 19.686 10.2593 19.8881 10.7665 20.2924C11.2823 20.6966 11.5401 21.2083 11.5401 21.8275ZM8.81951 21.7759C8.81951 21.4921 8.72065 21.2341 8.52295 21.0019C8.32524 20.7611 8.05446 20.6407 7.71062 20.6407C7.1089 20.6407 6.55446 20.8514 6.04729 21.2728C5.54873 21.6856 5.12752 22.1759 4.78368 22.7435C4.43984 23.3111 4.15617 23.8873 3.93268 24.4722C3.71777 25.057 3.58454 25.4182 3.53296 25.5558C5.27795 25.2634 6.59314 24.7689 7.47853 24.0723C8.37251 23.3756 8.81951 22.6102 8.81951 21.7759Z"
                            fill="#04B23D" />
                    </svg>
                    <p>BUY SELL ANYTIME ANYWHERE</p>
                </div>
                <div>
                    <h1>Get Started with Origo eMandi now</h1>
                    <div>
                        <input 
                            type="text" 
                            placeholder="Enter your Phone number" 
                            maxlength="10"
                        />
                        <button style={{cursor: "pointer" }}>Lets go</button>
                    </div>
                </div>
            </div>
            <div class="footerLower">
                <div class="aboutLinks">
                    <div>
                        <h3>Products</h3>
                        <a href="/#/eCashh" className="scroll-to-top">eCash</a>
                        <a href="/#/eresearchh" className="scroll-to-top">eResearch</a>
                        <a href="/#/eHedgee" className="scroll-to-top">eHedge</a>
                        <a href="/#/eSpott" className="scroll-to-top" >eSpot</a>
                        <a>eTV</a>
                        <a>eNews</a>
                    </div>
                    <div>
                        <h3>Company</h3>
                        <a href="/#/aboutuss" className="scroll-to-top">About Us</a>
                        <a href='https://corporate.origoemandi.com/become-origo-business-associate.php'>Business Associates</a>
                        <a href='https://corporate.origoemandi.com/our-services.php'>Careers</a>
                        <a class="cgp" className="scroll-to-top" href="/#/corporategovernancePolicy">Corporate Governance Policy</a>
                        <a href='https://corporate.origoemandi.com/contact-us.php'>Contact Us</a>
                        <a href='https://corporate.origoemandi.com/faqs.php'>FAQs</a>
                    </div>
                    <div>
                        <h3>Social</h3>
                        <a href='https://www.facebook.com/OrigoeMandi/'>Facebook</a>
                        <a href='https://www.linkedin.com/company/origo-emandi'>LinkedIn</a>
                        <a href='https://www.youtube.com/channel/UC69njTygd4MEMVxOhpAWUVA'>Youtube</a>
                        <a href='https://www.instagram.com/origo_emandi/?utm_medium=copy_link'>Instagram</a>
                        <a href='https://twitter.com/OrigoEMandi?t=IhlDQFCawnLUm6-I47SYLw&s=08'>Twitter</a>
                    </div>
                </div>
                <div class="aboutApps">
                    <div>
                        <span>Available on: </span>
                        <img src={appStoreImage} alt="" onClick={()=>window.open("https://apps.apple.com/us/app/origo-auction/id1560836342")}/>
                        <img src={playStoreImage} alt="" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.origo.auction")}/>
                        <img src={scanner} style={{cursor: "pointer"}} onclick="window.open('https://wa.me/message/MAJZQZV5VM7DD1?src=qr')" alt=""/>
                    </div>
                    <div>
                        <svg width="14" height="18" viewBox="0 0 11 13" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.4774 13.0042L5.09981 12.6805C4.57928 12.2447 0.0341797 8.31937 0.0341797 5.4481C0.0341797 2.4419 2.4712 0.00488281 5.4774 0.00488281C8.48359 0.00488281 10.9206 2.4419 10.9206 5.4481C10.9206 8.3194 6.37551 12.2447 5.85714 12.6827L5.4774 13.0042ZM5.4774 1.18187C3.12234 1.18453 1.21385 3.09302 1.21119 5.44807C1.21119 7.25187 4.00751 10.1334 5.4774 11.4523C6.94731 10.1329 9.7436 7.24969 9.7436 5.44807C9.74094 3.09302 7.83248 1.18456 5.4774 1.18187Z"
                                fill="#757575" />
                            <path
                                d="M5.47747 7.60582C4.28584 7.60582 3.31982 6.6398 3.31982 5.44817C3.31982 4.25654 4.28584 3.29053 5.47747 3.29053C6.6691 3.29053 7.63511 4.25654 7.63511 5.44817C7.63511 6.6398 6.66913 7.60582 5.47747 7.60582ZM5.47747 4.36932C4.88165 4.36932 4.39865 4.85233 4.39865 5.44815C4.39865 6.04396 4.88165 6.52697 5.47747 6.52697C6.07329 6.52697 6.55629 6.04396 6.55629 5.44815C6.55629 4.85233 6.07331 4.36932 5.47747 4.36932Z"
                                fill="#757575" />
                        </svg>
                        <span>
                        Office Thinkplus Workplaces,
                            <br/>
                            18 B, Institutional Area, Sector 32,
                            <p>Gurgaon, Haryana 122001</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PreRegFooter
