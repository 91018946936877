//on upcoming card click details
import React, { Component } from 'react';
import Ticker from "./ticker.js"
import { Scrollbars } from 'react-custom-scrollbars';
import CustomDropdown from '../../../components/CustomDropdown';
import CustomSlider from '../../../components/CustomSlider';
import CustomCarousel from '../../../components/CustomCarousel';
import UpcomingAuctionsCarousel from '../../../components/UpcomingAuctionsCarousel'
import auctionList from "./auctionList.json";
import CustomInputText from "../../../components/CustomInputText";
import CustomTable from '../../../components/CustomTable';
import CashandCarryPopUp from "./CashandCarryPopUp"
import CashandCarryConfimPopUp from './CashandCarryConfimPopUp'
import BuynowPopUp from './buynowPopUp'
import SetalertPopUp from './SetalertPopUp'
import Logo from "../../../assets/images/OrigoLogowhite.png"
import youtubelogo from "../../../assets/images/youtube.svg"
import linkedinlogo from "../../../assets/images/linkedinlogo.png"
import facebooklogo from "../../../assets/images/fblogo.png"
import CustomButton from '../../../components/CustomButton/index.js';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import WhatsappImage from "../../../assets/images/WhatsappImage.png";
import playStoreLogo from "../../../assets/images/category_7f907eb0.png";
import appleStoreLogo from "../../../assets/images/category_7f741d10.png";
import flag from '../../../assets/images/flag.png'
import Instagram from "../../../assets/images/Instagram.jpg"
import twitter from '../../../assets/images/twitter.png'
import LOGO from "../../../assets/images/e-Mandi White logo.png";
import iconplayvideo from '../../../assets/images/iconplayvideo.png'
import EmandiCashPopUp from './EmandiCashPopUp'
import KycPopUp from '../view/KycPopUp.js'
import eManditvlogo from '../../../assets/images/eManditvlogo.svg'
import eMandiNews from '../../../assets/images/eMandiNews.svg'
import EmandiCashStatusPopUp from './EmandiCashStatusPopUp'
import AnalyticsEventTracker from '../../AnalyticsEventTracker';
import EmandiCashList from './EmandiCashList'
import customerGrievancePDF from '../../../assets/pdf/customerGrievancePDF.pdf'
import commodityYearBook from '../../../assets/images/commodityYearBook.png'
import downloadIcon from '../../../assets/images/downloadIcon.png'
import commodityYearBook2023 from '../../../assets/pdf/commodityYearBook2023.pdf'
// const Slider = require('rc-slider');
// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);

import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';


class List extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        let { handelInputChange, state, formData, onSliderChange, onAuctionTypeChange,
            buynowPopUp, hidebuynowPopUp, onClickbuynowpaylater, onSubmitBuynow,
            onclickAvailnow, hideCashandCarryPopUp, cashandCarryPopUp, onSubmitcashandcarryPopUp,
            cashandCarryConfimPopUp, hidecashandCarryConfimPopUp, onhideSetalert,
            onClickSetalert, setalertPopUp, onSubmitsetalertPopUp, onClickviewmore,
            onClickCommodityresearch, onSubmitAuction, onClickRegister, onBidNowClick, timeremaining, calculatetime, onClickContactUS,
            onClickCashandCarryArbitage, onDisclaimerClick, onClickEmandiTV, onClickExplorenow, onClickEmandicashContinue, onAcceptKycPopup, onHideKycPopup,
            hideEmandicashPopup, onClickEmandiNews, onAcceptEmandiCashStatus, onCancelEmandiCashStatus, onClickEmandiCashicon, onClickCloseEmandiCashiconPopup
        } = this.props;
        console.log(this.props.state.buynowPopUp,"visable")
        let numVisible = 3, numScroll = 3;
        let mediaOnly = window.matchMedia("(max-width: 400px)");
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let gaEventTracker = AnalyticsEventTracker('Contact us');
        let column = [
            {
                "header": "Commodity",
                "field": "commodityName",
                renderer: ((rowData) => {
                    return ((rowData && rowData.commodityName) ? <div className="auction-id-byoutType"><div>{rowData && rowData.commodityName}</div></div> : "");
                })
            },

            {
                "header": "Rate Of Return",
                "field": "rateOfReturn",
                renderer: ((rowData) => {
                    return ((rowData && rowData.rateOfReturn) ? <div className="auction-id-byoutType"><div>{`${rowData && rowData.rateOfReturn}%`}</div></div> : "0");
                })
            },
            {
                "header": "Lock in period",
                "field": "lockInPeriod",
                renderer: ((rowData) => {
                    return ((rowData && rowData.lockInPeriod) ? <div className="auction-id-byoutType"><div>{`${rowData && rowData.lockInPeriod} ${rowData.lockInPeriod === 1 ? "day" : "days"}`}</div></div> : "0");
                })
            },
            {
                "header": "",
                "field": "",
                renderer: ((rowData) => {
                    return ((rowData && rowData) ? <div className="auction-id-byoutType"><div><CustomButton label="Avail now" onClick={() => onclickAvailnow(rowData)} /></div></div> : "");
                })
            }
        ];


        if (mediaOnly.matches) {
            numVisible = 1;
            numScroll = 1;
            // this.props.renderMobileApp();
        }

        return (<div className="landing-home-page" >
            <Ticker />
            <Scrollbars autoHide={false} style={{ width: "100%", height: "93vh" }}>

                <BuynowPopUp visible={this.props.state.buynowPopUp} onHide={hidebuynowPopUp} onCancel={hidebuynowPopUp} handelInputChange={handelInputChange} formData={formData} onSubmitBuynow={onSubmitBuynow} state={state} />
                <CashandCarryPopUp visible={cashandCarryPopUp} onHide={hideCashandCarryPopUp} onCancel={hideCashandCarryPopUp} onSubmitcashandcarryPopUp={onSubmitcashandcarryPopUp} handelInputChange={handelInputChange} formData={formData} state={state} />
                <CashandCarryConfimPopUp visible={cashandCarryConfimPopUp} onHide={hidecashandCarryConfimPopUp} onCancel={hidecashandCarryConfimPopUp} formData={formData} bodyContend={formData.dailogContend} state={state} requestId={state.requestId} />
                <SetalertPopUp visible={setalertPopUp} onHide={onhideSetalert} onCancel={onhideSetalert} handelInputChange={handelInputChange} formData={formData} onSubmitsetalertPopUp={onSubmitsetalertPopUp} onClickRegister={onClickRegister} />
                <EmandiCashPopUp visible={state.showemandicashPopUp} onClickEmandicashContinue={onClickEmandicashContinue} onHide={hideEmandicashPopup} />
                <KycPopUp visible={state.kycPopup} onAcceptKycPopup={onAcceptKycPopup} />
                <EmandiCashStatusPopUp visible={state.emandicashstatusPopup} onAcceptEmandiCashStatus={onAcceptEmandiCashStatus} status={state.status} onCancelEmandiCashStatus={onCancelEmandiCashStatus} />
                <EmandiCashList visible={state.emandicashListPopup} path={state.emandiCashList} onHide={onClickCloseEmandiCashiconPopup} />
                <div className="create-filter-auction">
                    <div className="create-auction-wrapper">
                        <p>Create New Auction</p>
                        <div className='buy-sell-wrapper'>
                            <div className="auction-type">
                                <p className={`${state.activeAuctionType === "buy" ? "active" : ""}`} onClick={() => onAuctionTypeChange("buy")}>Buy</p>
                                <p className={`${state.activeAuctionType === "sell" ? "active" : ""}`} onClick={() => onAuctionTypeChange("sell")}>Sell</p>
                            </div>
                            <CustomInputText label="Commodity" name='commidityname' value={formData.commidityname} config={{ onChange: handelInputChange }} />
                            <CustomInputText label="Location" name='location' value={formData.location} config={{ onChange: handelInputChange }} />
                            <div className="qty-class">
                                <CustomInputText className='quantity-cls' label="Quantity" name='quantity' value={formData.quantity} config={{ onChange: handelInputChange, keyfilter: "num", maxlength: 10 }} />
                                {/* <CustomInputText className='unit-cls' label="Unit" name='unit' value={formData.unit} config={{ onChange: handelInputChange, maxlength: 10 }} /> */}

                                <CustomDropdown label="Unit" name='unit' value={formData.unit} config={{ onChange: handelInputChange }} options={state.option} />
                            </div>
                            {userInfo ? "" : <CustomInputText label={state.activeAuctionType === "buy" ? "Buyer's Name" : "Seller's Name"} name='name' value={formData.name} config={{ onChange: handelInputChange, placeholder: state.activeAuctionType === "buy" ? "Buyer's Name" : "Seller's Name" }} />}
                            <div className='flag-text-class'>
                                {userInfo ? "" :
                                    // <CustomInputText label={state.activeAuctionType === "buy" ? "Buyer's Mobile Number" : "Seller's Mobile Number"} name='number' value={formData.number} config={{ onChange: handelInputChange, keyfilter: "num", maxlength: 10 }} />}

                                    //     <div class="input-container">
                                    //      <i class="fa fa-user icon">
                                    //                     <img src={flag} />
                                    //                     </i><span className='code'>+91</span>
                                    // <CustomInputText label={state.activeAuctionType === "buy" ? "Buyer's Mobile Number" : "Seller's Mobile Number"} name='number' value={formData.number} config={{ onChange: handelInputChange, keyfilter: "num", maxlength: 10 }} />

                                    //    </div>
                                    <div className="inputfield-cls">
                                        <p>{state.activeAuctionType === "buy" ? "Buyer's Mobile Number" : "Seller's Mobile Number"}</p>
                                        <div class="input-container">
                                            <i className=" icon">
                                                <img src={flag} />
                                            </i><span className='code'>+91</span>
                                            <div className="input-cls">
                                                <CustomInputText name='number' value={formData.number} config={{ onChange: handelInputChange, keyfilter: "num", maxlength: 10 }} />

                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>
                            <p className="submit-btn" onClick={onSubmitAuction}>Submit</p>
                        </div>
                        <div className="buynow-paylater-div">
                            <div className="buy-now-cls buy-now-pay-later-cls" onClick={onClickbuynowpaylater}>
                                <p className="buynow-label" >Buy Now Pay Later</p>
                                <div className="fin-cls">Finance your purchase</div>
                            </div>
                            <div className="commodity-cls" onClick={onClickCommodityresearch}>Commodity Research</div>
                            {/* <p onClick={onClickSetalert}>set alert</p> */}
                            {/* <div className="commodity-cls" onClick={onClickEmandiTV}>EMandi TV</div> */}
                            <div className="buy-now-cls" onClick={onClickEmandiTV}>
                                <p className="buynow-label">eMandi TV<img src={eManditvlogo} /></p>
                                <div className="sub-label">Intitutive way to learn commodity research</div>
                            </div>
                            <div className="buy-news" onClick={onClickEmandiNews}>
                            <p className="buynews-label">eMandi News<img src={eMandiNews} /></p>
                                </div>
                            {/* <div className="buy-now-cls" onClick={onClickEmandiNews}> */}
                                {/* <p className="buynow-label">eMandiNews<img src={eMandiNews} /></p> */}
                                {/* <div className="sub-label">Intitutive way to learn commodity research</div> */}
                            {/* </div> */}
                            {/* <div className="commodity-cls" onClick={onClickCashandCarryArbitage}>Cash & Carry Arbitrage</div> */}

                        </div>
                    </div>
                    <div className="filter-auction-warpper">
                    <div className="containe">
                        <Link to='/account'>
                            <button className="act-btn">Visit Profile</button>
                        </Link>
                    </div>                 
                        <div className='emandi-cash-cls'>
                        
                            <div className='icons-cls'>
                                <img src={iconplayvideo} className="finance-icon-img" onClick={onClickEmandiCashicon} />
                                <span>watch video</span>
                            </div>
                            <div>
                                <p className='logo'>eMandi Cash</p>
                                <p>Get cash for your supplies- up to <i class="fa fa-inr"></i> 2 crores</p>
                            </div>
                            <CustomButton label="Explore Now" onClick={() => { gaEventTracker('EmandiCash'); onClickExplorenow() }} className="new-commodity-btn-cls" />

                        </div>
                        <div className='year-book-cls'>
                            <div className='year-book-img-cls'>
                                <img src={commodityYearBook} className='year-book-banner'/>
                            </div>
                            <div className="btn-class">
                            <a href={commodityYearBook2023} download="Commodity Year Book 2023" className="btn-download" >
                            <img src={downloadIcon} className='icon-img' /> &nbsp;
                            <button className="bttn">Download Now</button>
                                    </a>
                                    </div>
                            </div>

                        {/* <div className="filter-list">
                            <CustomDropdown name="auctionType" value={formData.auctionType} onChange={handelInputChange} options={state.auctionTypeDropDown} config={{ placeholder: "Auction Type", showClear: true }} />
                            <CustomDropdown name="commodityName" value={formData.commodityName} onChange={handelInputChange} options={state.commodityDropDownList} config={{ filter: true, placeholder: "Commodities", showClear: true }} />
                            <CustomDropdown name="locationName" value={formData.locationName} onChange={handelInputChange} options={state.locationDropDownList} config={{ filter: true, placeholder: "Locations", showClear: true }} /> */}
                            {/* <CustomSlider name="slider" label={"Quantity"} value={formData.slider} onChange={onSliderChange} /> */}

                            {/* {formData.slider[1] ?
                                <div className="custom-silder">
                                    <p className="silder-qty">Quantity</p>
                                    <div>
                                        <p className="slider-range-details">Range: {formData.sliderValue[0]} - {formData.sliderValue[1] ? formData.sliderValue[1] : formData.slider[1]}</p>
                                        <Range className={'custom-slider'} name="slider" min={10} max={100}  tipFormatter={value => `${value}`} />
                                    </div>
                                </div>
                            : ""} */}
                            {/* {formData.slider[1] ?
                                <div className="custom-silder">
                                    <p className="silder-qty">Quantity</p>
                                    <div>
                                        <p className="slider-range-details">Range: {formData.sliderValue[0]} - {formData.sliderValue[1] ? formData.sliderValue[1] : formData.slider[1]}</p>
                                        <Range className={'custom-slider'} name="slider" min={formData.slider[0]} max={formData.slider[1]} defaultValue={[formData.slider[0], formData.slider[1]]} onAfterChange={onSliderChange} tipFormatter={value => `${value}`} />
                                    </div>
                                </div>
                                : ""}
                        </div> */}
                        {state.activeAuctionList && state.activeAuctionList.length ? <React.Fragment>
                            <span className='view-more-cls' onClick={() => onClickviewmore("liveAuction")}>View More</span>
                            <div className="auction-list">
                                <CustomCarousel value={state.activeAuctionList || []} footerText={"Closes in"} header={"Live Auctions"} onBidNowClick={onBidNowClick} numVisible={numVisible} numScroll={numScroll} buttonText={"Bid Now"} timeremaining={timeremaining} calculatetime={calculatetime} />
                            </div>
                        </React.Fragment> : ""}
                        {state.scheduledAuctionList && state.scheduledAuctionList.length ? <React.Fragment>
                            <span className='upcomingauction-view-more-cls' onClick={() => onClickviewmore("scheduleAuction")}>View More</span>
                            <div className="auction-list">
                                <UpcomingAuctionsCarousel value={state.scheduledAuctionList || []} header={"Upcoming Auctions"} onClickSetalert={onClickSetalert}  onBidNowClick={onBidNowClick} numVisible={numVisible} numScroll={numScroll} buttonText={"Set Alert"} timeremaining={timeremaining} calculatetime={calculatetime} />
                            </div>
                        </React.Fragment> : ""}
                        {/* <div className="table-cls" >
                            <h5 onClick={onClickCashandCarryArbitage}>Cash & Carry Arbitrage</h5>
                            <div className="customTable-cls" >
                                <Scrollbars autoHide={false} style={{ width: "100%", height: "35vh" }}>

                                <CustomTable columns={column} data={state.cashandcarryList} className="bids-activity-grid-cls" config={{ scrollable: true, scrollHeight: "250px" }} />
                                </Scrollbars>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="footer">
                    <div className="footer-address-cls">
                        <img src={LOGO} className='logo' />
                        <div className="social-logo">
                            <img onClick={() => window.open("https://www.facebook.com/OrigoeMandi/")} src={facebooklogo} />
                            <img onClick={() => window.open("https://www.linkedin.com/company/origo-emandi ")} src={linkedinlogo} />
                            <img onClick={() => window.open("https://www.youtube.com/channel/UC69njTygd4MEMVxOhpAWUVA")} src={youtubelogo} />
                            <img onClick={() => window.open("https://instagram.com/origo_emandi?utm_medium=copy_link")} src={Instagram} />
                            <img onClick={() => window.open("https://twitter.com/OrigoEMandi?t=IhlDQFCawnLUm6-I47SYLw&s=08")} src={twitter} />
                        </div>

                        <div className="footercontent-cls">

                            <div className='whatsapp-details'>
                                <p>WhatsApp</p>
                                <img className='whatsapp-cls' onClick={() => window.open("https://wa.me/message/MAJZQZV5VM7DD1")} src={WhatsappImage} />
                                <p className='whatsapp-cls' onClick={() => window.open("https://wa.me/message/MAJZQZV5VM7DD1")}>Scan or click</p>
                            </div>
                            <div className='store-cls'>
                                <span>Download Our App</span>
                                <div className="store-link-apple-play">
                                    <img onClick={() => window.open("https://play.google.com/store/apps/details?id=com.origo.auction")} src={playStoreLogo} alt="playStoreLogo" />
                                    <img onClick={() => window.open("https://apps.apple.com/us/app/origo-auction/id1560836342")} src={appleStoreLogo} alt="appleStoreLogo" />
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <div className="footercontent-cls">
                        <span>Services</span>
                        <ul>
                            <li onClick={() => window.open("https://www.origoindia.com/trade-facilitation/")}>Trade Facilitation</li>
                            <li onClick={() => window.open("https://www.origoindia.com/agri-commodity-warehousing/")}>Agri-Commodity Warehousing</li>
                            <li onClick={() => window.open("https://emandi.origoindia.com/#/signin")}>Origo eMandi</li>
                            <li onClick={() => window.open("https://www.origoindia.com/services/#procurement-finance")}>Procurement Finance</li>
                            <li onClick={() => window.open("https://www.origoindia.com/exchange-services/")}>Exchange Services</li>
                        </ul>
                    </div> */}
                    <div className="footercontent-cls">
                        <span>Useful links</span>
                        {/* <ul>
                            <li onClick={() => window.open("https://www.origoindia.com/about-us/")}>About Us</li>
                            <li onClick={() => window.open(" https://www.origoindia.com/leadership/")}>Leadership</li>
                            <li onClick={() => window.open("https://www.origoindia.com/our-people/")}>Our People</li>
                            <li onClick={() => window.open("https://www.origoindia.com/media/")}>Media Coverage</li>
                            <li onClick={()=> window.open("https://www.origoindia.com/contact/")}>Contact Us</li>
                        </ul> */}
                        <ul>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/about-us")}>About Us</li>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/our-services")}>Our Services</li>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/emandi-lenders.php")}>Lenders</li>
                            <li onClick={() => window.open(customerGrievancePDF)}>Customer Grievance </li>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/corporate-gorvernance-policy.php")}>Corporate Governance</li>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/become-origo-business-associate")}>Business Associates</li>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/contact-us")}>Contact Us</li>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/faqs.php")}>FAQs</li>
                            <li onClick={() => window.open("https://corporate.origoemandi.com/blog/")}>Blog</li>
                        </ul>
                    </div>
                    <div className="contactInfo-cls">
                        <span>Contact Info</span>
                        <div className='address-cls'>
                            <i className="pi pi-map-marker"></i>
                            <ul>
                                <p>Address:</p>
                                <li>Plot #37, 1st floor</li>
                                <li>Sector 18, Institutional Area</li>
                                <li>Gurgaan, Haryana 122002</li>
                            </ul>
                        </div>
                        <div className='address-cls'>
                            <i class="fa fa-phone" aria-hidden="true"></i>

                            <div>
                                <ul>
                                    <p>Phone Number</p>
                                    <li>+91-8338047777</li>
                                </ul>
                            </div>
                        </div>
                        <div className='address-cls'>
                            <i className="pi pi-envelope"></i>
                            <div>
                                <ul>
                                    <p>Email Id</p>
                                    <li>sales@origoemandi.com</li>
                                </ul>
                            </div>
                        </div>
                        <div className='terms-cls'>
                            <p onClick={() => window.open("https://corporate.origoemandi.com/termsofservice")}>Terms & Conditions</p>
                            <p onClick={onDisclaimerClick}>Disclaimer </p>
                            <p onClick={() => window.open("https://corporate.origoemandi.com/privacy-policy")}>Privacy Policy</p>
                        </div>
                    </div>

                </div>
            </Scrollbars>

        </div>);
    }
}

export default List;