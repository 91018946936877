import React, { useEffect } from 'react';
import './Footer.css';
import LOGO from "../../../assets/images/e-Mandi Final Logo.svg";
import qrCode from "../../../assets/images/WhatsappImage.png";
import playStore from "../../../assets/images/GooglePlayLogo.svg";
import logowhite1 from "../../../assets/images/logowhite1.png";
import AppStore from "../../../assets/images/image 42.png";
import PlayStore from "../../../assets/images/image 43.png";
import { history } from '../../../utils/Utility';

function Footer() {

    useEffect(() => {
        function scrollToTop() {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    
        const anchors = document.querySelectorAll('.scroll-to-top');
        anchors.forEach(anchor => anchor.addEventListener('click', scrollToTop));
    
        return () => {
          anchors.forEach(anchor => anchor.removeEventListener('click', scrollToTop));
        };
      }, []);
    return (
        <>
            <div >
                <div >
                    <footer className='Footer'>
                        <section className='upperFooter'>
                            <div className='upperinnerFooterDiv'>
                                <img src={logowhite1} alt="eMandi Logo" />
                                <p className='buy'>BUY SELL ANYTIME ANYWHERE</p>
                            </div>
                            {/* <div style={{ float:"right",width:"40%",marginTop:"80px",marginRight:"-80px" }}>
                                <p style={{fontWeight:"600",fontSize:"15pt",marginBottom:"20px",letterSpacing:"1px",wordSpacing:"1px"}}>Get Started With Origo eMandi Now</p>
                                <div class="input-group mb-3">
                                    <input type="text" class=" inputField" placeholder="Enter your phone number" aria-label="Enter your phone number" aria-describedby="basic-addon2" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary inputButton" style={{color:"white",borderRadius:"0px 5px 5px 0px",width:"100px"}} type="button">Let's go</button>
                                    </div>
                                </div>

                            </div> */}
                        </section>

                        <section className='lowerFooter'>
                            <div className='lowerinnerDiv'>
                                <div className='div1'>
                                    <p className='headings'>Products</p>
                                    <div className='links1'>
                                        <a className='links scroll-to-top'  onClick={(e) => { e.preventDefault(); history.push('/ecashComingSoon'); }}>eCash</a>
                                        <a className='links scroll-to-top'  onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/eResearch');
                                            }} 
                                            >eResearch
                                        </a>
                                        <a className='links scroll-to-top'  onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/eHedge');
                                        }}  >eHedge</a>
                                        <a className='links scroll-to-top'  onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/eSpot');
                                        }}>eSpot</a>
                                        <a className='links scroll-to-top' onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/emandiTVNew');
                                        }}>eTV</a>
                                        <a className='links scroll-to-top' onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/eMandiNews');
                                        }}>eNews</a>
                                    </div>  
                                </div>

                                <div className='div2'>
                                    <p className='headings'>Company</p>
                                    <div className='links2'>
                                    <a className='links scroll-to-top'  onClick={() => history.push('/AboutUsPost')}>About Us</a>
                                        <a className='links'  onClick={() => window.open("https://corporate.origoemandi.com/become-origo-business-associate")}  >Business Associates</a>
                                        <a className='links'  onClick={() => window.open("https://corporate.origoemandi.com/our-services")}  >Careers</a>
                                        <a className='links scroll-to-top'  href="/#/corporategovernancePolicyy">Corporate Governance Policy</a>
                                        <a className='links'  onClick={() => window.open("https://corporate.origoemandi.com/contact-us")}  >Contact Us</a>
                                        <a className='links'  onClick={() => window.open("https://corporate.origoemandi.com/faqs.php")}  >FAQs</a>
                                    </div>
                                </div>

                                <div className='div3'>
                                    <p className='headings'>Social</p>
                                    <div className='links3'>
                                        <a className='links'  onClick={() => window.open("https://www.facebook.com/OrigoeMandi/")}  >Facebook</a>
                                        <a className='links'  onClick={() => window.open("https://www.linkedin.com/company/origo-emandi ")}  >LinkedIn</a>
                                        <a className='links'  onClick={() => window.open("https://www.youtube.com/channel/UC69njTygd4MEMVxOhpAWUVA")}  >Youtube</a>
                                        <a className='links'  onClick={() => window.open("https://instagram.com/origo_emandi?utm_medium=copy_link")}  >Instagram</a>
                                        <a className='links'  onClick={() => window.open("https://twitter.com/OrigoEMandi?t=IhlDQFCawnLUm6-I47SYLw&s=08")}  >Twitter</a>
                                    </div>
                                </div>

                                <div className='div4'>
                                    <div className='availableOnSection'>
                                        <p className='headings'>Available on:</p>
                                        <img className="googleImages" onClick={()=>window.open("https://apps.apple.com/us/app/origo-auction/id1560836342")} src={AppStore} alt="" />
                                        <img className="googleImages" src={PlayStore} alt="" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.origo.auction")} />
                                        <img className="scanner" onClick={()=>window.open("https://wa.me/918338047777?text=I%20want%20to%20use%20Origo%20eMandi")} src={qrCode} alt="QR"/>
                                    </div>
                                    <p className='address'><i class="fa fa-map-marker">&nbsp;</i> Office Thinkplus Workplaces,
<br></br>18 B, Institutional Area, Sector 32,
 <br></br>Gurgaon, Haryana 122001</p>
                                </div>
                            </div>
                        </section>
                    </footer>

                </div>
            </div>
        </>
    )
}

export default Footer